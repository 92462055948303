import React from 'react';
import { VictoryChart, VictoryLine, VictoryAxis, VictoryScatter } from 'victory';
import { IReportTeamMemberCard } from 'types/report';

interface IProps {
	title: string;
	i18n: Record<string, string>;
	data: IReportTeamMemberCard[];
}

function chunkArray<T>(arr: T[], chunkSize: number): T[][] {
	let arrCopy = [...arr];
	let result = [];
	while (arrCopy.length > 0) {
		result.push(arrCopy.splice(0, chunkSize));
	}
	return result;
}

const Members = ({ title, i18n, data }: IProps) => {
	let chunkedMembers = chunkArray(data, 2);
	let turnoverCounter = 0;
	return (
		<div className='section member-cards'>
			<div className='section-header'>
				<span className='title'>{title}</span>
			</div>
			{chunkedMembers.map((line, lineNumber) => {
				return (
					<div className='member-cards container dont-break' key={`member-card-line-${lineNumber}`}>
						{line.map((member, memberNumber) => {
							let scoreHistory = member.scores.slice(0).map((score, index) => {
								let scoreValue = score === null ? null : score;
								return {
									x: index,
									y: scoreValue,
								};
							});
							return (
								<div
									className={`member-cards box ${!member.currentScore.value ? 'disabled' : ''}`}
									key={`member-number-${lineNumber}-${memberNumber}`}>
									{member.turnoverRisk === 'low' ? null : (
										<div className={`member-cards turnover-risk ${member.turnoverRisk}`}>
											<span className='member-cards turnover-risk-label'>
												{turnoverCounter++ + 1 && null}
												{member.turnoverRisk === 'high'
													? i18n['turnover-risk.high']
													: member.turnoverRisk === 'medium'
													? i18n['turnover-risk.medium']
													: null}
											</span>
										</div>
									)}
									<div className='member-cards-header-section'>
										<div className={`member-cards name-header ${member.currentScore.category}`}>
											<span>
												{member.name
													.split(' ')
													.map(
														(n, i) =>
															(i === 0 || i === member.name.split(' ').length - 1) && n[0]
													)
													.filter((n) => n)
													.join('')}
											</span>
										</div>
										<div className='member-cards name-section'>
											<span className='member-cards-name'>{member.name}</span>
											<span className='member-cards-role'> {member.role} </span>

											{member.missedSurveys > 0 ? (
												<span className='member-cards member-cards-missed-survey'>
													⚠️ {member.missedSurveys + ' '}
													{member.missedSurveys === 1
														? i18n['cards.label.week-missed']
														: i18n['cards.label.weeks-missed']}
												</span>
											) : null}
										</div>
										<div className={`member-cards main-score`}>
											<span
												className={`member-cards main-score-label ${member.currentScore.category}`}>
												{member.currentScore.value === null
													? i18n['cards.label.no-data']
													: member.currentScore.value >= 9
													? i18n['cards.label.very-engaged']
													: member.currentScore.value >= 8
													? i18n['cards.label.engaged']
													: member.currentScore.value >= 6
													? i18n['cards.label.neutral']
													: i18n['cards.label.disengaged']}
											</span>

											<div className='member-cards main-score-container'>
												<span
													className={`member-cards main-score-value ${member.currentScore.category}`}>
													{member.currentScore.value === 10
														? member.currentScore.value
														: member.currentScore.value?.toFixed(1) || `-`}
												</span>
											</div>
										</div>
									</div>
									<div className='member-cards break-row' />

									<div className='member-cards-results-section'>
										<div className='member-cards previous-scores-header'>
											<span>{i18n['cards.previous-results']}</span>
										</div>

										<div className='member-results-chart'>
											<VictoryChart
												height={180}
												width={700}
												domainPadding={{ x: 0, y: 30 }}
												padding={{ top: 10, bottom: 10, left: 30, right: 30 }}>
												<VictoryAxis
													style={{
														axis: {
															stroke: 'none',
														},
														ticks: {
															stroke: 'transparent',
														},
														tickLabels: {
															fill: 'transparent',
														},
													}}
												/>
												<VictoryLine
													data={scoreHistory}
													style={{
														data: { strokeWidth: 4 },
													}}
													interpolation='monotoneX'
												/>
												<VictoryScatter
													data={scoreHistory}
													labels={({ datum }) =>
														datum.y === null ? datum.y : datum.y.toFixed(1)
													}
													size={4}
													style={{
														labels: { fontSize: 26 },
													}}
												/>
											</VictoryChart>
										</div>
									</div>
									<div className='member-cards-metrics-container'>
										<div className='member-cards-metrics-section'>
											<span>⭐️ {i18n['cards.doing-great']}</span>
											<div className='member-cards-metrics-values'>
												<span className='doing-great-metric'>
													{i18n['metric.' + member.doingGreat.metric + '.name'] || `-`}
												</span>
												<span className='doing-great-score '>
													{member.doingGreat.score?.toFixed(1)}
												</span>
											</div>
										</div>
										<div className='member-cards-metrics-section'>
											<span>🔨 {i18n['cards.needs-improvement']}</span>
											<div className='member-cards-metrics-values'>
												<span className='needs-improvement-metric'>
													{i18n['metric.' + member.needsImprovement.metric + '.name'] || `-`}
												</span>
												<span className='needs-improvement-score'>
													{member.needsImprovement.score?.toFixed(1)}
												</span>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				);
			})}
			{turnoverCounter ? (
				<div className='turnover-disclaimer container'>
					<p className='turnover-disclaimer title'>{i18n['turnover-risk.disclaimer.title']}</p>
					<p className='turnover-disclaimer description'>{i18n['turnover-ristk.disclaimer.description-1']}</p>
					<p>{i18n['turnover-ristk.disclaimer.description-2']} </p>
				</div>
			) : null}
		</div>
	);
};

export default Members;
