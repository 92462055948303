import React from 'react';
import ReactMarkdown from 'react-markdown';
import { IReportActionSteps } from 'types/report';

interface IProps {
	title: string;
	i18n: Record<string, string>;
	data: IReportActionSteps;
}

const Actions = ({ title, i18n, data }: IProps) => {
	let singularOrPlural = data.employees.length > 1 ? 'plural' : 'singular';

	return (
		<div className='section'>
			<div className='section-header'>
				<span className='title'>{title}</span>
			</div>
			<div className='action-steps-header'>
				{data.employees.map((employee, index, array) => {
					return (
						<span className='action-steps-header-employee'>
							{index + 1 === array.length && array.length > 1
								? ' ' + i18n['conjunctions.and'] + ' '
								: index
								? ', '
								: ''}
							{employee}
						</span>
					);
				})}
				<span>
					{' '}
					{i18n['actions.lowest-scores-in.' + singularOrPlural]} {i18n['metric.' + data.metric + '.name']}.
				</span>
				<br />
				<span>{i18n['actions.can-do.' + singularOrPlural]}</span>
			</div>
			<div className='action-steps container'>
				{data.steps.map((steps) => {
					return (
						<div>
							<div className='action-square'></div>
							<div className='action-content'>
								<ReactMarkdown>{steps}</ReactMarkdown>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Actions;
