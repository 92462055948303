import { IReport } from './types/report';

export const dummyReport: IReport = {
	reportType: 'leadership',
	header: {
		date: '27 – 07 January 2022',
		company: 'Dummy',
		team: '',
		companyLogo: '/static/media/circful-logo.70639fe5.svg',
	},
	summary: {
		participation: {
			percentage: 85,
			count: 36,
			total: 42,
		},
		score: {
			value: 9,
			change: {
				value: -0.5,
				category: 'unfavorable',
			},
		},
		scoreHistory: [
			{
				period: '01 – 12 Nov',
				score: {
					value: 8.8,
				},
				trailingScore: {
					value: 8.7,
				},
			},
			{
				period: '15 – 26 Nov',
				score: {
					value: 9.2,
				},
				trailingScore: {
					value: 8.8,
				},
			},
			{
				period: '29 – 10 Dec',
				score: {
					value: 8.7,
				},
				trailingScore: {
					value: 8.8,
				},
			},
			{
				period: '13 – 24 Dec',
				score: {
					value: 9.5,
				},
				trailingScore: {
					value: 8.9,
				},
			},
			{
				period: '27 – 07 Jan',
				score: {
					value: 9,
				},
				trailingScore: {
					value: 8.9,
				},
			},
		],
		weeksOfData: 10,
	},
	engagement: [
		{
			metric: 'feedback-and-recognition',
			score: {
				value: 9,
				category: 'favorable',
				change: {
					value: 0,
					category: 'neutral',
				},
			},
			distribution: [
				{
					value: 0,
					category: 'unfavorable',
				},
				{
					value: 0,
					category: 'neutral',
				},
				{
					value: 100,
					category: 'favorable',
				},
			],
			trailingScore: {
				value: 8.2,
				category: 'favorable',
			},
			companyScore: {
				value: 8.2,
				category: 'none',
			},
		},
		{
			metric: 'expectations',
			score: {
				value: 7.8,
				category: 'neutral',
				change: {
					value: -1.7,
					category: 'unfavorable',
				},
			},
			distribution: [
				{
					value: 0,
					category: 'unfavorable',
				},
				{
					value: 25,
					category: 'neutral',
				},
				{
					value: 75,
					category: 'favorable',
				},
			],
			trailingScore: {
				value: 8.8,
				category: 'favorable',
			},
			companyScore: {
				value: 8.8,
				category: 'none',
			},
		},
		{
			metric: 'meaningful-work',
			score: {
				value: 9.5,
				category: 'favorable',
				change: {
					value: 0.5,
					category: 'favorable',
				},
			},
			distribution: [
				{
					value: 0,
					category: 'unfavorable',
				},
				{
					value: 0,
					category: 'neutral',
				},
				{
					value: 100,
					category: 'favorable',
				},
			],
			trailingScore: {
				value: 9.1,
				category: 'favorable',
			},
			companyScore: {
				value: 9.1,
				category: 'none',
			},
		},
		{
			metric: 'manager-support',
			score: {
				value: 9.8,
				category: 'favorable',
				change: {
					value: -0.2,
					category: 'unfavorable',
				},
			},
			distribution: [
				{
					value: 0,
					category: 'unfavorable',
				},
				{
					value: 0,
					category: 'neutral',
				},
				{
					value: 100,
					category: 'favorable',
				},
			],
			trailingScore: {
				value: 9.4,
				category: 'favorable',
			},
			companyScore: {
				value: 9.4,
				category: 'none',
			},
		},
		{
			metric: 'ambassadorship',
			score: {
				value: 9.5,
				category: 'favorable',
				change: {
					value: -0.2,
					category: 'unfavorable',
				},
			},
			distribution: [
				{
					value: 0,
					category: 'unfavorable',
				},
				{
					value: 0,
					category: 'neutral',
				},
				{
					value: 100,
					category: 'favorable',
				},
			],
			trailingScore: {
				value: 9,
				category: 'favorable',
			},
			companyScore: {
				value: 9,
				category: 'none',
			},
		},
		{
			metric: 'psychological-safety',
			score: {
				value: 8.4,
				category: 'favorable',
				change: {
					value: -1.3,
					category: 'unfavorable',
				},
			},
			distribution: [
				{
					value: 0,
					category: 'unfavorable',
				},
				{
					value: 40,
					category: 'neutral',
				},
				{
					value: 60,
					category: 'favorable',
				},
			],
			trailingScore: {
				value: 9,
				category: 'favorable',
			},
			companyScore: {
				value: 9,
				category: 'none',
			},
		},
		{
			metric: 'growth',
			score: {
				value: 9.4,
				category: 'favorable',
				change: {
					value: -0.3,
					category: 'unfavorable',
				},
			},
			distribution: [
				{
					value: 0,
					category: 'unfavorable',
				},
				{
					value: 0,
					category: 'neutral',
				},
				{
					value: 100,
					category: 'favorable',
				},
			],
			trailingScore: {
				value: 8.8,
				category: 'favorable',
			},
			companyScore: {
				value: 8.8,
				category: 'none',
			},
		},
	],
	questionList: [
		{
			name: 'feedback-and-recognition',
			content: 'survey-statement.feedback-and-recognition.survey-9',
		},
		{
			name: 'expectations',
			content: 'survey-statement.expectations.survey-9',
		},
		{
			name: 'meaningful-work',
			content: 'survey-statement.meaningful-work.survey-9',
		},
		{
			name: 'manager-support',
			content: 'survey-statement.manager-support.survey-9',
		},
		{
			name: 'ambassadorship',
			content: 'survey-statement.ambassadorship.survey-10',
		},
		{
			name: 'psychological-safety',
			content: 'survey-statement.psychological-safety.survey-10',
		},
		{
			name: 'growth',
			content: 'survey-statement.growth.survey-10',
		},
	],
	teamHighlights: {
		metrics: [
			{
				name: 'participation-percentage',
			},
			{
				name: 'overall-engagement',
				hasChange: true,
			},
			{
				name: 'feedback-and-recognition',
			},
			{
				name: 'expectations',
			},
			{
				name: 'meaningful-work',
			},
			{
				name: 'manager-support',
			},
			{
				name: 'ambassadorship',
			},
			{
				name: 'psychological-safety',
			},
			{
				name: 'growth',
			},
		],
		members: [
			{
				name: 'Company',
				scores: [
					{
						value: 85,
						category: 'none',
					},
					{
						value: 9,
						category: 'none',
						change: {
							value: -0.3,
							category: 'unfavorable',
						},
					},
					{
						value: 9,
						category: 'none',
					},
					{
						value: 7.8,
						category: 'none',
					},
					{
						value: 9.5,
						category: 'none',
					},
					{
						value: 9.8,
						category: 'none',
					},
					{
						value: 9.5,
						category: 'none',
					},
					{
						value: 8.4,
						category: 'none',
					},
					{
						value: 9.4,
						category: 'none',
					},
				],
			},
			{
				name: 'Frontend Team',
				scores: [
					{
						value: 83,
						category: 'favorable',
					},
					{
						value: 9.3,
						category: 'favorable',
						change: {
							value: -0.1,
							category: 'unfavorable',
						},
					},
					{
						value: 6,
						category: 'unfavorable',
					},
					{
						value: 8.1,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 7.4,
						category: 'neutral',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 8.1,
						category: 'favorable',
					},
					{
						value: 9.2,
						category: 'favorable',
					},
				],
			},
			{
				name: 'Backend Tean',
				scores: [
					{
						value: 95,
						category: 'favorable',
					},
					{
						value: 8.6,
						category: 'favorable',
						change: {
							value: -0.4,
							category: 'unfavorable',
						},
					},
					{
						value: 8.5,
						category: 'favorable',
					},
					{
						value: 7.5,
						category: 'neutral',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
				],
			},
			{
				name: 'Ops Team',
				scores: [
					{
						value: 90,
						category: 'favorable',
					},
					{
						value: 8.2,
						category: 'favorable',
						change: {
							value: +0.2,
							category: 'favorable',
						},
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 8,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 8.3,
						category: 'favorable',
					},
					{
						value: 9.5,
						category: 'favorable',
					},
				],
			},
			{
				name: 'Marketing Team',
				scores: [
					{
						value: 70,
						category: 'neutral',
					},
					{
						value: 9.3,
						category: 'favorable',
						change: {
							value: 0.0,
							category: 'neutral',
						},
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 8,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 7.5,
						category: 'neutral',
					},
					{
						value: 7.1,
						category: 'neutral',
					},
					{
						value: 8.3,
						category: 'favorable',
					},
					{
						value: 9.5,
						category: 'favorable',
					},
				],
			},
		],
	},
	teamMemberHighlights: {
		metrics: [
			{
				name: 'surveys-answered',
			},
			{
				name: 'overall-engagement',
				hasChange: true,
			},
			{
				name: 'feedback-and-recognition',
			},
			{
				name: 'expectations',
			},
			{
				name: 'meaningful-work',
			},
			{
				name: 'manager-support',
			},
			{
				name: 'ambassadorship',
			},
			{
				name: 'psychological-safety',
			},
			{
				name: 'growth',
			},
		],
		members: [
			{
				name: 'Company ',
				team: '',
				scores: [
					{
						value: 9,
						category: 'none',
					},
					{
						value: 9,
						category: 'none',
						change: {
							value: -0.5,
							category: 'unfavorable',
						},
					},
					{
						value: 9,
						category: 'none',
					},
					{
						value: 7.8,
						category: 'none',
					},
					{
						value: 9.5,
						category: 'none',
					},
					{
						value: 9.8,
						category: 'none',
					},
					{
						value: 9.5,
						category: 'none',
					},
					{
						value: 8.4,
						category: 'none',
					},
					{
						value: 9.4,
						category: 'none',
					},
				],
			},
			{
				name: 'Tom Smith',
				team: 'Backend',
				scores: [
					{
						value: 1,
						category: 'neutral',
					},
					{
						value: 8.3,
						category: 'favorable',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: 8,
						category: 'favorable',
					},
					{
						value: 8,
						category: 'favorable',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
				],
			},
			{
				name: 'Jon Snow',
				team: 'Backend',
				scores: [
					{
						value: 2,
						category: 'favorable',
					},
					{
						value: 8.7,
						category: 'favorable',
						change: {
							value: -0.3,
							category: 'unfavorable',
						},
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 7,
						category: 'neutral',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
				],
			},
			{
				name: 'Flash Gordon',
				team: 'Backend',
				scores: [
					{
						value: 0,
						category: 'unfavorable',
					},
					{
						value: null,
						category: 'none',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
				],
			},
			{
				name: 'Arya Stark',
				team: 'Frontend',
				scores: [
					{
						value: 2,
						category: 'favorable',
					},
					{
						value: 8.6,
						category: 'favorable',
						change: {
							value: -1,
							category: 'unfavorable',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: 8,
						category: 'favorable',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 6,
						category: 'neutral',
					},
					{
						value: 9,
						category: 'favorable',
					},
				],
			},
			{
				name: 'Tyrion Lannister',
				team: 'Frontend',
				scores: [
					{
						value: 1,
						category: 'neutral',
					},
					{
						value: 8.7,
						category: 'favorable',
						change: {
							value: -1.3,
							category: 'unfavorable',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: 7,
						category: 'neutral',
					},
					{
						value: 9,
						category: 'favorable',
					},
				],
			},
			{
				name: 'Rupert Smith',
				team: 'Frontend',
				scores: [
					{
						value: 2,
						category: 'favorable',
					},
					{
						value: 9.7,
						category: 'favorable',
						change: {
							value: 0.1,
							category: 'favorable',
						},
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 8,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
				],
			},
			{
				name: 'José António',
				team: 'Frontend',
				scores: [
					{
						value: 1,
						category: 'neutral',
					},
					{
						value: 10,
						category: 'favorable',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
				],
			},
			{
				name: 'Claúdia Tomás',
				team: 'Frontend',
				scores: [
					{
						value: 0,
						category: 'unfavorable',
					},
					{
						value: null,
						category: 'none',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
				],
			},
			{
				name: 'João Quadros',
				team: 'Frontend',
				scores: [
					{
						value: 0,
						category: 'unfavorable',
					},
					{
						value: null,
						category: 'none',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
				],
			},
			{
				name: 'Kirby Branch',
				team: 'Ops',
				scores: [
					{
						value: 1,
						category: 'neutral',
					},
					{
						value: 5.0,
						category: 'unfavorable',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: 5,
						category: 'unfavorable',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: 6,
						category: 'unfavorable',
					},
					{
						value: 4,
						category: 'unfavorable',
					},
				],
			},
			{
				name: 'Willow Wynn',
				team: 'Ops',
				scores: [
					{
						value: 2,
						category: 'favorable',
					},
					{
						value: 7.2,
						category: 'neutral',
						change: {
							value: 0.1,
							category: 'favorable',
						},
					},
					{
						value: 6,
						category: 'unfavorable',
					},
					{
						value: 8,
						category: 'favorable',
					},
					{
						value: 7,
						category: 'neutral',
					},
					{
						value: 7,
						category: 'neutral',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 5,
						category: 'unfavorable',
					},
				],
			},
			{
				name: 'Muhammad Donnelly',
				team: 'Ops',
				scores: [
					{
						value: 2,
						category: 'favorable',
					},
					{
						value: 8.5,
						category: 'favorable',
						change: {
							value: 0.1,
							category: 'favorable',
						},
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 8,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
				],
			},
			{
				name: 'Safwan Gallagher',
				team: 'Ops',
				scores: [
					{
						value: 1,
						category: 'neutral',
					},
					{
						value: 10,
						category: 'favorable',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
				],
			},
			{
				name: 'Kady Buxton',
				team: 'Ops',
				scores: [
					{
						value: 0,
						category: 'unfavorable',
					},
					{
						value: null,
						category: 'none',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
				],
			},
			{
				name: 'Aqib Power',
				team: 'Ops',
				scores: [
					{
						value: 0,
						category: 'unfavorable',
					},
					{
						value: null,
						category: 'none',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
				],
			},
			{
				name: 'Rohaan Couch',
				team: 'Marketing Team',
				scores: [
					{
						value: 1,
						category: 'neutral',
					},
					{
						value: 5.0,
						category: 'unfavorable',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: 5,
						category: 'unfavorable',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: 6,
						category: 'unfavorable',
					},
					{
						value: 4,
						category: 'unfavorable',
					},
				],
			},
			{
				name: 'Neve Haigh',
				team: 'Marketing Team',
				scores: [
					{
						value: 2,
						category: 'favorable',
					},
					{
						value: 7.2,
						category: 'neutral',
						change: {
							value: 0.1,
							category: 'favorable',
						},
					},
					{
						value: 6,
						category: 'unfavorable',
					},
					{
						value: 8,
						category: 'favorable',
					},
					{
						value: 7,
						category: 'neutral',
					},
					{
						value: 7,
						category: 'neutral',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 5,
						category: 'unfavorable',
					},
				],
			},
			{
				name: 'Bella Metcalfe',
				team: 'Marketing Team',
				scores: [
					{
						value: 2,
						category: 'favorable',
					},
					{
						value: 7.6,
						category: 'neutral',
						change: {
							value: 0.1,
							category: 'favorable',
						},
					},
					{
						value: 7,
						category: 'neutral',
					},
					{
						value: 8,
						category: 'favorable',
					},
					{
						value: 8,
						category: 'favorable',
					},
					{
						value: 7,
						category: 'neutral',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 6,
						category: 'unfavorable',
					},
				],
			},
			{
				name: 'Angelo Wormald',
				team: 'Marketing Team',
				scores: [
					{
						value: 2,
						category: 'favorable',
					},
					{
						value: 8.5,
						category: 'favorable',
						change: {
							value: 0.1,
							category: 'favorable',
						},
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 8,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
				],
			},
			{
				name: 'Ida Tanner',
				team: 'Marketing Team',
				scores: [
					{
						value: 2,
						category: 'favorable',
					},
					{
						value: 8.7,
						category: 'favorable',
						change: {
							value: 0.1,
							category: 'favorable',
						},
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 9,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
				],
			},
			{
				name: 'Kory Alcock',
				team: 'Marketing Team',
				scores: [
					{
						value: 1,
						category: 'neutral',
					},
					{
						value: 10,
						category: 'favorable',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: 10,
						category: 'favorable',
					},
					{
						value: 10,
						category: 'favorable',
					},
				],
			},
			{
				name: 'Tyreke Holt',
				team: 'Marketing Team',
				scores: [
					{
						value: 0,
						category: 'unfavorable',
					},
					{
						value: null,
						category: 'none',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
				],
			},
			{
				name: 'Tyreke Holt',
				team: 'Marketing Team',
				scores: [
					{
						value: 0,
						category: 'unfavorable',
					},
					{
						value: null,
						category: 'none',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
				],
			},
			{
				name: 'Tyreke Holt',
				team: 'Marketing Team',
				scores: [
					{
						value: 0,
						category: 'unfavorable',
					},
					{
						value: null,
						category: 'none',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
				],
			},
			{
				name: 'Tyreke Holt',
				team: 'Marketing Team',
				scores: [
					{
						value: 0,
						category: 'unfavorable',
					},
					{
						value: null,
						category: 'none',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
				],
			},
			{
				name: 'Tyreke Holt',
				team: 'Marketing Team',
				scores: [
					{
						value: 0,
						category: 'unfavorable',
					},
					{
						value: null,
						category: 'none',
						change: {
							value: 0,
							category: 'neutral',
						},
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
					{
						value: null,
						category: 'none',
					},
				],
			},
		],
	},
	teamMemberCards: [
		{
			name: 'Tom Smith',
			role: 'Backend Developer',
			missedSurveys: 1,
			currentScore: {
				value: 5.4,
				category: 'unfavorable',
			},
			scores: [8.1, 8.1, null, 8.3],
			doingGreat: {
				metric: 'ambassadorship',
				score: 9,
			},
			needsImprovement: {
				metric: null,
				score: null,
			},
			turnoverRisk: 'high',
		},
		{
			name: 'Jon Snow',
			role: 'Frontend developer',
			missedSurveys: 0,
			currentScore: {
				value: 8.6,
				category: 'favorable',
			},
			scores: [9, 8.3, 9.6, 8.6],
			doingGreat: {
				metric: 'manager-support',
				score: 10,
			},
			needsImprovement: {
				metric: 'psychological-safety',
				score: 6,
			},
			turnoverRisk: 'medium',
		},
		{
			name: 'Flash Gordon',
			role: 'Backend Developer',
			missedSurveys: 0,
			currentScore: {
				value: 8.7,
				category: 'favorable',
			},
			scores: [8.7, 8.4, 9, 8.7],
			doingGreat: {
				metric: 'feedback-and-recognition',
				score: 9,
			},
			needsImprovement: {
				metric: 'expectations',
				score: 7,
			},
			turnoverRisk: 'low',
		},
		{
			name: 'Arya Stark',
			role: 'Frontend developer',
			missedSurveys: 1,
			currentScore: {
				value: 8.7,
				category: 'favorable',
			},
			scores: [9.1, 8.4, 10, 8.7],
			doingGreat: {
				metric: 'manager-support',
				score: 10,
			},
			needsImprovement: {
				metric: 'psychological-safety',
				score: 7,
			},
			turnoverRisk: 'low',
		},
		{
			name: 'Tyrion Lannister',
			role: 'Frontend developer',
			missedSurveys: 0,
			currentScore: {
				value: 9.7,
				category: 'favorable',
			},
			scores: [10, 10, 9.6, 9.7],
			doingGreat: {
				metric: 'feedback-and-recognition',
				score: 10,
			},
			needsImprovement: {
				metric: null,
				score: null,
			},
			turnoverRisk: 'low',
		},
		{
			name: 'Rupert Smith',
			role: 'Frontend developer',
			missedSurveys: 1,
			currentScore: {
				value: 10,
				category: 'favorable',
			},
			scores: [10, 9, null, 10],
			doingGreat: {
				metric: 'manager-support',
				score: 10,
			},
			needsImprovement: {
				metric: null,
				score: null,
			},
			turnoverRisk: 'low',
		},
		{
			name: 'José António',
			role: 'Frontend developer',
			missedSurveys: 2,
			currentScore: {
				value: null,
				category: 'disabled',
			},
			scores: [9.7, null, null, null],
			doingGreat: {
				metric: null,
				score: null,
			},
			needsImprovement: {
				metric: null,
				score: null,
			},
			turnoverRisk: 'low',
		},
		{
			name: 'Claúdia Tomás',
			role: 'Frontend developer',
			missedSurveys: 2,
			currentScore: {
				value: null,
				category: 'disabled',
			},
			scores: [10, null, null, null],
			doingGreat: {
				metric: null,
				score: null,
			},
			needsImprovement: {
				metric: null,
				score: null,
			},
			turnoverRisk: 'low',
		},
		{
			name: 'João Quadros',
			role: 'Backend Developer',
			missedSurveys: 2,
			currentScore: {
				value: null,
				category: 'disabled',
			},
			scores: [null, null, null, null],
			doingGreat: {
				metric: null,
				score: null,
			},
			needsImprovement: {
				metric: null,
				score: null,
			},
			turnoverRisk: 'low',
		},
	],
	teamMemberComments: {
		sections: [
			{
				metric: 'feedback-and-recognition',
				question: 'positive-question.feedback-and-recognition.survey-3',
				comments: [
					{
						employee: null,
						role: null,
						content: 'In 1:1 meetings',
					},
					{
						employee: null,
						role: null,
						content: 'on 1on1 meetings and talking with my coworker',
					},
					{
						employee: null,
						role: null,
						content:
							'This is very true. We work in team and all of my work is revised by other colleagues. This is a opportunity to make questions and suggestions about my work. So, receive this feedback about my work I also can improve.',
					},
					{
						employee: null,
						role: null,
						content: "I have 1on1's monthly with my dev leader",
					},
				],
			},
			{
				metric: 'expectations',
				question: 'positive-question.expectations.survey-3',
				comments: [
					{
						employee: null,
						role: null,
						content:
							'I think the most part that help me to understand how my performance is evaluated was from code review and than my tasks done on Jira',
					},
					{
						employee: null,
						role: null,
						content: 'Current feedbacks',
					},
				],
			},
			{
				metric: 'expectations',
				question: 'negative-question.expectations.survey-3',
				comments: [
					{
						employee: null,
						role: null,
						content:
							'I think the best way to clarify standards is with the help of the manager and while evaluating my work, describe and suggest what I can improve in my work.',
					},
				],
			},
			{
				metric: 'meaningful-work',
				question: 'positive-question.meaningful-work.survey-3',
				comments: [
					{
						employee: null,
						role: null,
						content:
							'I work for the software of the company. I know that if I do my job well I will contribute for a better and reliable system that will make clients happy.',
					},
					{
						employee: null,
						role: null,
						content:
							'Working as a team to reach the same goal. We work together to develop the best product we could',
					},
				],
			},
			{
				metric: 'manager-support',
				question: 'positive-question.manager-support.survey-4',
				comments: [
					{
						employee: null,
						role: null,
						content: 'always seeks to monitor my performance and supports me when I need',
					},
					{
						employee: null,
						role: null,
						content: 'Always available to help and the generosity he shows.',
					},
					{
						employee: null,
						role: null,
						content:
							'Since my first day at the company i had just one bad day, and that day i felt that not only my manager, but others coworkers care about eachother',
					},
					{
						employee: null,
						role: null,
						content: 'Talking to me and listening when I want to talk',
					},
				],
			},
			{
				metric: 'ambassadorship',
				question: 'positive-question.ambassadorship.survey-3',
				comments: [
					{
						employee: null,
						role: null,
						content:
							"There are a lot of to talk about this. But the most proud is the company for sure, not just for the company it self, but how everyone respect eachother. It seems like this should be the basic thing, but unfortunately it's not like this everywhere",
					},
					{
						employee: null,
						role: null,
						content: 'I would say so. I am very happy to be part of this team.',
					},
					{
						employee: null,
						role: null,
						content:
							'To be a software developer in an international company working with people from different countries and developing a good product with quality and reliability',
					},
				],
			},
			{
				metric: 'psychological-safety',
				question: 'positive-question.psychological-safety.survey-4',
				comments: [
					{
						employee: null,
						role: null,
						content: 'changing positions or starting in something that I had no experience',
					},
					{
						employee: null,
						role: null,
						content:
							'I feel a lot of freedom when developing, and I think people is open to hear different solutions and take risks at some degree.',
					},
					{
						employee: null,
						role: null,
						content: 'New projects and new technical challenges',
					},
				],
			},
			{
				metric: 'psychological-safety',
				question: 'negative-question.psychological-safety.survey-4',
				comments: [
					{
						employee: null,
						role: null,
						content:
							'Maybe more discussions about architecture and layout are good to think about it more and be more innovative with it',
					},
				],
			},
			{
				metric: 'growth',
				question: 'positive-question.growthgrowth.survey-4',
				comments: [
					{
						employee: null,
						role: null,
						content: 'I get feedback and challenges frequently that help me to grow',
					},
					{
						employee: null,
						role: null,
						content:
							'My company supports my development in the way it direct and help me during the development',
					},
					{
						employee: null,
						role: null,
						content:
							'When I have blockers or need some help, I always have support for what i need to be done',
					},
					{
						employee: null,
						role: null,
						content: '1on1s, feedbacks and new challenges',
					},
				],
			},
		],
	},
	leadershipTip: {
		metric: 'expectations',
		title: 'Define an effective goal-setting framework',
		illustration:
			'https://mcusercontent.com/c4b40aebf67bfaffae27b5202/images/2ee42e79-2c34-07fa-ba56-4d37ec3d1d6a.png',
		button: {
			label: 'Quick guide',
			url: 'https://cloudfil.es/BNA0ZSXQXFO',
		},
		markdown:
			'Forward-thinking organizations are shifting to a culture of outcomes. Do you have an effective goal-setting framework in place? Consider if you are being able to sustain the following 3 factors:\n\n-   **Focus:**  Is your organization’s focus clear to everyone so teams can make better decisions, prioritize, and execute effectively?\n\n-   **Accountability**: Is everyone clear about their responsibilities, from the top down so effective work can be accomplished?\n\n-   **Purpose**: Do your employees know the purpose of what they’re doing and how their work benefits the organization, their coworkers, and customers?\n\nThe OKR methodology brings these benefits to a business. Here’s a **quick guide** you can share with your managers on **how to define Objectives and Key Results (OKRs)**, while applying goal-setting best practices like the SMART framework:',
	},
	actionSteps: {
		metric: 'expectations',
		employees: ['Arya Stark', 'Jon Snow'],
		steps: [
			'1.  **Ask your team to revise their job descriptions.** Then compare together the differences between what you wrote and try to get a consensus of what their role should look like.',
			'2.  **Review your team-drafted job descriptions.** Where are you aligned? Where do your understandings of their role differ? Take notes and mock up the doc.',
			'3. **Clarify and collaborate with your employees:** by sharing your version on a 1-1. By doing it, not only will you offer an opportunity for them to share what they like and dislike about their job, but also understand what can be restructured.',
		],
	},
	howAreTheseScoresCalculated: [
		'**Scale**',
		'We gather responses to our questions on a 10 - point scale ranging from 1 to 10. We chose this scale because it’s simple, intuitive and consistent. Research also shows that a 10-point scale offers a more granular, varied data set than the commonly used 5-point Likert scale (Leung, 2011).',
		'**Participation Rate, Overall Engagement Score and 2-Weeks Metric Scores**',
		'The majority of the data present in this report is calculated from the answers given in the past 2 weeks. Every 2 weeks we gather data from one question per engagement metric.',
		'This means the data revealed has been collected over the past 2 surveys. The participation rate is calculated for that same period and shows the percent age of Active members. Active members are those who have answered at least one survey in that last 2 weeks.',
		'**10-Weeks Average - Team & Company Metric Scores**',
		"It is an aggregated rolling 10 weeks weighted average and is based on answers members have given on all our different surveys. This means, these scores aggregate all our questions related to the 7 metrics of engagement. The scores are refreshed once a week as the survey responses are calculated and aggregated. Each employee has the same weight in the calculation and everyone's scores are aggregated equally.",
	],
	i18n: {
		'engagement.title': 'Key Drivers of Engagement',
		'questions.title': 'Last 2 Weeks Survey Questions',
		'team.highlights.title': 'Last 2 Weeks - Team Highlights',
		'team.member-highlights.title': 'Last 2 Weeks - Team Member Highlights',
		'team.member-comments.title': 'Last 2 Weeks - Team Member Comments',
		// 'focus.title': 'Where You Should Focus',
		'action-points.title': 'Action Points For The Highest Priority Individuals',
		'scores-explanation.title': 'How Are These Scores Calculated',
		'report.manager.title': 'Manager Report',
		'report.leadership.title': 'Leadership Report',
		// 'action-points.title': 'Action Points For The Highest Priority Individuals',
		'engagement.label.favorable': 'Favorable (8-10)',
		'engagement.label.neutral': 'Neutral (6-7)',
		'engagement.label.unfavorable': 'Unfavorable (1-5)',
		'engagement.label.metric': 'Metric',
		'engagement.label.score-header': 'Last 2 Weeks Score',
		'engagement.label.distribution-header': 'Scores Distribution (%)',
		'engagement.label.trailing-header': 'Last 10-Weeks Avg.',
		'engagement.disclaimer':
			'*This section contains only {weeksOfData} weeks of data as there weren’t 10 weeks of available records',
		team: 'Team',
		company: 'Company',
		'cards.previous-results': 'Previous Scores',
		'cards.engagement-score': 'Engagement Score',
		'cards.doing-great': 'Doing great on',
		'cards.needs-improvement': 'Needs improvement on',
		'cards.label.missed-survey': '1 missed survey',
		'cards.label.missed-surveys': '2 missed surveys',
		'cards.label.week-missed': 'week missed',
		'cards.label.weeks-missed': 'weeks missed',
		'cards.label.week-streak': 'week streak',
		'cards.label.week-streaks': 'week streaks',
		'cards.label.no-data': 'No Data',
		'cards.label.very-engaged': 'Very Engaged',
		'cards.label.engaged': 'Engaged',
		'cards.label.neutral': 'Neutral',
		'cards.label.disengaged': 'Disengaged',
		'summary.participation-rate': '2-Weeks Participation Rate',
		'summary.score': '2-Weeks Average Score',
		'summary.history': 'Comparison with 10-Weeks prior (2-Weeks value)',
		'summary.disabled': 'This section will only be available in the next report.',
		'summary.label.2-weeks-data': '2 weeks data',
		'summary.label.10-weeks-trendline': '10 weeks trendline',
		'out-of': 'out of',
		'focus.title': 'Where you Should Focus',
		'metric.feedback-and-recognition.name': 'Feedback & Recognition',
		'metric.expectations.name': 'Expectations',
		'metric.meaningful-work.name': 'Meaningful Work',
		'metric.manager-support.name': 'Manager Support',
		'metric.ambassadorship.name': 'Ambassadorship',
		'metric.psychological-safety.name': 'Psychological Safety',
		'metric.growth.name': 'Growth',
		'metric.feedback-and-recognition.short-desc':
			'Do team members receive enough high-quality feedback and recognition?',
		'metric.expectations.short-desc': 'Do team members have clarity around what is expected from them?',
		'metric.meaningful-work.short-desc': 'Do team members see the purpose behind the work they do?',
		'metric.manager-support.short-desc': 'Do team members receive enough support from their manager?',
		'metric.ambassadorship.short-desc':
			'Are team members likely to reccomend your organization as a good place to work?',
		'metric.psychological-safety.short-desc':
			'Do team members feel confident to ask for help or to take risks without fear of negative repercussions?',
		'metric.growth.short-desc': 'Do team members have opportunities for growth in their professional development?',
		'metric.feedback-and-recognition.desc': 'Do team members receive enough high-quality feedback and recognition?',
		'metric.expectations.desc':
			'Do team members have clarity around what is expected from them? Is it clear what success looks like for their role?',
		'metric.meaningful-work.desc':
			'Do team members see the purpose behind the work they do? Do they have the opportunity to use their strengths?',
		'metric.manager-support.desc':
			'Do team members receive enough support from their manager? Are communications of good quality and frequency?',
		'metric.ambassadorship.desc':
			'Are team members likely to reccomend your organization as a good place to work? Are they proud of your organization?',
		'metric.psychological-safety.desc':
			"Do team members feel confident to admit when they don’t know something, to ask for help when they're stuck, and to take risks, without fear of negative repercussions?",
		'metric.growth.desc':
			'Do team members have opportunities for growth and feel supported in their professional development?',
		'highlights.feedback-and-recognition.name': 'Feedback & Recognition',
		'highlights.expectations.name': 'Expectations',
		'highlights.meaningful-work.name': 'Meaningful Work',
		'highlights.manager-support.name': 'Manager Support',
		'highlights.ambassadorship.name': 'Ambassadorship',
		'highlights.psychological-safety.name': 'Psychological Safety',
		'highlights.growth.name': 'Growth',
		'highlights.participation-percentage.name': 'Participation (%)',
		'highlights.overall-engagement.name': 'Overall Engagement',
		'highlights.surveys-answered.name': 'Surveys Answered',
		'survey-statement.feedback-and-recognition.survey-1':
			'I get enough feedback to understand if I’m doing my job well.',
		'survey-statement.expectations.survey-1': 'I know what is expected of me at work.',
		'survey-statement.meaningful-work.survey-1': 'I have the opportunity to work on the tasks I do best.',
		'survey-statement.manager-support.survey-1': 'I have the support I need to complete my work.',
		'survey-statement.ambassadorship.survey-2': 'I would recommend my company as a great place to work.',
		'survey-statement.psychological-safety.survey-2':
			'Employees at my company can voice their opinions without fear of retribution or rejection.',
		'survey-statement.growth.survey-2': 'I’m exposed to talented coworkers which I can reach out for advice.',
		'survey-statement.meaningful-work.survey-3': 'I know how my work contributes to the goals of my company.',
		'survey-statement.feedback-and-recognition.survey-3': 'I receive detailed feedback that helps me improve.',
		'survey-statement.expectations.survey-3': 'I understand the standards by which my performance is evaluated.',
		'survey-statement.ambassadorship.survey-3': 'I am proud of where I work.',
		'survey-statement.growthgrowth.survey-4':
			'I receive the support and resources I need to develop professionally.',
		'survey-statement.manager-support.survey-4': 'My manager genuinely cares about my wellbeing.',
		'survey-statement.psychological-safety.survey-4':
			'We are encouraged to be innovative even though some of our initiatives may not succeed.',
		'survey-statement.meaningful-work.survey-5':
			'The mission or purpose of my company makes me feel my job is important.',
		'survey-statement.psychological-safety.survey-5':
			'I can easily find space for collaboration and conversations with others.',
		'survey-statement.growth.survey-5': 'I believe there are good career opportunities for me at my company.',
		'survey-statement.ambassadorship.survey-5':
			'The overall business goals and strategies set by senior leadership are taking the company in the right direction.',
		'survey-statement.feedback-and-recognition.survey-6': 'If I do good work, I know that it will be recognized.',
		'survey-statement.manager-support.survey-6':
			'My manager is regularly checking in with me, to help me with my work challenges.',
		'survey-statement.expectations.survey-6': 'I know what I need to do to be successful in my role.',
		'survey-statement.feedback-and-recognition.survey-7': 'My job performance is evaluated fairly.',
		'survey-statement.expectations.survey-7': 'I understand how my work supports the goals of my team.',
		'survey-statement.meaningful-work.survey-7': 'I have the opportunity to do challenging things at work.',
		'survey-statement.manager-support.survey-7': 'When I ask my manager for help, they can devote time to help me.',
		'survey-statement.ambassadorship.survey-8': 'Workloads are divided fairly among people at my company.',
		'survey-statement.psychological-safety.survey-8':
			'No one on my team would deliberately act in a way that undermines my efforts.',
		'survey-statement.growth.survey-8': 'I have access to the learning and development I need to do my job well.',
		'survey-statement.feedback-and-recognition.survey-9':
			'In the last seven days, I have received recognition or praise for doing good work.',
		'survey-statement.expectations.survey-9': 'I have clear goals for my work and an action plan to achieve them.',
		'survey-statement.meaningful-work.survey-9': 'The work I do is meaningful to me.',
		'survey-statement.manager-support.survey-9':
			'My manager keeps me informed about what is happening in our organization.',
		'survey-statement.ambassadorship.survey-10':
			'I believe my total compensation is fair, relative to similar roles at other companies.',
		'survey-statement.psychological-safety.survey-10': 'It is safe to take a risk on my team.',
		'survey-statement.growth.survey-10': 'I am given opportunities to develop skills relevant to my interests.',
		'positive-question.feedback-and-recognition.survey-1':
			'What are some ways your manager has given you quality feedback?',
		'positive-question.expectations.survey-1': 'In what ways have your manager helped you have clear expectations?',
		'positive-question.meaningful-work.survey-1': 'What tasks do you enjoy the most?',
		'positive-question.manager-support.survey-1': 'What are some examples of the support you are receiving?',
		'positive-question.ambassadorship.survey-2':
			'What are some of the reasons you would recommend your company as a great place to work?',
		'positive-question.psychological-safety.survey-2':
			'What’s one thing your organization does well to make sure everyone can voice their opinions?',
		'positive-question.growth.survey-2':
			'Can you share an example of when you have received useful advice from a coworker?',
		'positive-question.meaningful-work.survey-3':
			'What are some of the ways your work contributes to the goals of your company?',
		'positive-question.feedback-and-recognition.survey-3':
			'What are some examples where you have received relevant feedback?',
		'positive-question.expectations.survey-3': 'What has helped you understand how your performance is evaluated?',
		'positive-question.ambassadorship.survey-3': 'What are you most proud of?',
		'positive-question.growthgrowth.survey-4': 'In what ways have your company supported your development?',
		'positive-question.manager-support.survey-4':
			'In what ways have your manager showed that they care about your wellbeing?',
		'positive-question.psychological-safety.survey-4':
			'In what ways have you been encouraged to be innovative and take risks?',
		'positive-question.meaningful-work.survey-5': 'What is making you feel your job is important?',
		'positive-question.psychological-safety.survey-5':
			'In what ways have your manager helped promote collaboration and conversations within your team?',
		'positive-question.growth.survey-5': 'What are some of the career opportunities you would like to embrace?',
		'positive-question.ambassadorship.survey-5':
			'In what ways is the leadership making sure your company is in the right direction?',
		'positive-question.feedback-and-recognition.survey-6': 'In what ways have you been recognized?',
		'positive-question.manager-support.survey-6':
			'What are some of the things your manager does well to support you in your work?',
		'positive-question.expectations.survey-6':
			'What has helped you gain clarity on what success means for your role?',
		'positive-question.feedback-and-recognition.survey-7':
			'What has led you to feel your performance was being evaluated fairly?',
		'positive-question.expectations.survey-7':
			'What’s one thing your manager does well to make sure your work supports team goals?',
		'positive-question.meaningful-work.survey-7':
			'What has been the most challenging tasks you have enjoyed so far?',
		'positive-question.manager-support.survey-7': 'In what ways is the help from your manager valuable?',
		'positive-question.ambassadorship.survey-8':
			'What do you suggest your manager keep doing to divide workload fairly?',
		'positive-question.psychological-safety.survey-8':
			'In what ways does your team show your efforts are valued and appreciated?',
		'positive-question.growth.survey-8':
			'Can you share an example of a learning and development opportunity you’ve had recently?',
		'positive-question.feedback-and-recognition.survey-9':
			'What was the best recognition you’ve gotten in your career?',
		'positive-question.expectations.survey-9':
			'How has it helped you in your job to have clear goals and an action plan?',
		'positive-question.meaningful-work.survey-9': 'In what ways is your work meaningful to you?',
		'positive-question.manager-support.survey-9':
			'Can you share an example that shows your manager keeps you informed?',
		'positive-question.ambassadorship.survey-10': 'What do you value the most in your compensation package?',
		'positive-question.psychological-safety.survey-10': 'Why do you say it is safe to take risks on your team?',
		'positive-question.growth.survey-10': 'Which skills have you developed recently?',
		'negative-question.feedback-and-recognition.survey-1': 'In what ways would you like to receive more feedback?',
		'negative-question.expectations.survey-1': 'What could be done to help you have clearer expectations?',
		'negative-question.meaningful-work.survey-1': 'What are some tasks you would like to do more of?',
		'negative-question.manager-support.survey-1': 'In what ways could your manager better support you?',
		'negative-question.ambassadorship.survey-2':
			'What changes would make you more likely to recommend your company as a great place to work?',
		'negative-question.psychological-safety.survey-2':
			'Can you share an example of when you felt it was unsafe to voice an opinion?',
		'negative-question.growth.survey-2': 'What’s stopping you from accessing advice from talented coworkers?',
		'negative-question.meaningful-work.survey-3':
			'Why it can be hard to see how your work contributes to the company goals?',
		'negative-question.feedback-and-recognition.survey-3':
			'In what ways would you like to receive feedback differently?',
		'negative-question.expectations.survey-3':
			'What would help you clarify the standards by which your performance is evaluated?',
		'negative-question.ambassadorship.survey-3': 'What would increase your sense of pride?',
		'negative-question.growthgrowth.survey-4':
			'What type of support would you like to receive to develop professionally?',
		'negative-question.manager-support.survey-4':
			'How would you like your manager to show that they care about your wellbeing?',
		'negative-question.psychological-safety.survey-4': 'What could be done to encourage more innovation?',
		'negative-question.meaningful-work.survey-5': 'What would help you improve your sense of meaning at work?',
		'negative-question.psychological-safety.survey-5':
			'What are some ways that your manager could help to promote more collaboration and conversations within your team?',
		'negative-question.growth.survey-5': 'What type of career opportunities are you missing?',
		'negative-question.ambassadorship.survey-5':
			'What are some things the senior leadership should do to make sure your company is in the right direction?',
		'negative-question.feedback-and-recognition.survey-6': 'How would you like to be recognized more often?',
		'negative-question.manager-support.survey-6':
			'What’s one thing your manager could improve to better support you?',
		'negative-question.expectations.survey-6':
			'Can you identify a time when you felt it was unclear what you needed to do to be successful in your role?',
		'negative-question.feedback-and-recognition.survey-7':
			'Can you identify a time when you felt your performance wasn’t evaluated fairly?',
		'negative-question.expectations.survey-7':
			'What’s one thing your manager could do to make sure your work supports team goals?',
		'negative-question.meaningful-work.survey-7': 'What are some challenging tasks you would like to embrace?',
		'negative-question.manager-support.survey-7':
			'In your opinion, how can your manager improve the support they give you?',
		'negative-question.ambassadorship.survey-8': 'In what way do you feel workload could be divided more fairly?',
		'negative-question.psychological-safety.survey-8':
			'Can you identify a time when you felt your efforts where undermined?',
		'negative-question.growth.survey-8': 'What type of learning and development are you lacking?',
		'negative-question.feedback-and-recognition.survey-9':
			'How often do you typically want to receive recognition, and from whom?',
		'negative-question.expectations.survey-9': 'How is the lack of goals or an action plan affecting your work?',
		'negative-question.meaningful-work.survey-9': 'What needs to improve for your work to become more meaningful?',
		'negative-question.manager-support.survey-9':
			'What are some of the things would you like to be more informed about?',
		'negative-question.ambassadorship.survey-10':
			'Why do you think your compensation package is not completely fair?',
		'negative-question.psychological-safety.survey-10':
			'Can you share an example where you felt it was unsafe to take risks on your team?',
		'negative-question.growth.survey-10': 'What type of development opportunities would you value the most?',
		'actions.lowest-scores-in.singular': 'had the lowest scores in',
		'actions.lowest-scores-in.plural': 'had the lowest scores in',
		'actions.can-do.singular': 'Take special attention to them this week. Here are small things you can do:',
		'actions.can-do.plural': 'Take special attention to them this week. Here are small things you can do:',
		'conjunctions.and': 'and',
		'comments.empty':
			"Your team members didn't share any comments this week. Try asking them to share more specific feedback.",
		'engagement.label.distribution-no-value': 'There were no scores for this driver in the last 2 weeks.',
		'turnover-risk.medium': '🚧 Medium Risk of Turnover',
		'turnover-risk.high': '🚨 High Risk of Turnover',
		'turnover-risk.label': 'Alert:',
		'turnover-risk.disclaimer.title': 'Turnover Forecast',
		'turnover-ristk.disclaimer.description-1':
			'Circful now learns from previous scores, feedback and employee exits to predict the risk of each individual leaving in the next 6 weeks.',
		'turnover-ristk.disclaimer.description-2':
			'If the probability of an employee leaving is above 70%, then we label it as 🚧  Medium Risk of Turnover, if the probability is above 90%, then we label it as 🚨 High Risk of Turnover.',
	},
};
