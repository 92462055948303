import React from 'react';
import { IReportQuestionItem } from 'types/report';

interface IProps {
	title: string;
	i18n: Record<string, string>;
	data: IReportQuestionItem[];
}

const Questions = ({ title, i18n, data }: IProps) => {
	return (
		<div className='section'>
			<div className='section-header'>
				<span className='title'>{title}</span>
			</div>
			<div>
				{data.map((query) => {
					if (query.content)
						return (
							<div className='question'>
								<span className='question-name'>{i18n['metric.'+query.name+'.name']}</span>
								<span className='question-content'>{i18n[query.content]}</span>
							</div>
						);
					else return null;
				})}
			</div>
		</div>
	);
};

export default Questions;
