import React from 'react';
import { IReportHeader } from 'types/report';

interface IProps {
	data: IReportHeader;
	title: string;
}

const Header = ({ data, title }: IProps) => {
	return (
		<div className='section report-header'>
			<div className='section-header'>
				<img alt='Company Logo' className='company-logo' src={data.companyLogo} />
				<img alt='Circful Logo' className='circful-logo' />
				<span className='title report-title'>{title}</span>
				<span className='report-date'>{data.date}</span>
			</div>
		</div>
	);
};

export default Header;
