import './App.css';
import React, { useState, useEffect } from 'react';
import Summary from './components/Summary';
import Engagement from './components/Engagement';
import Highlights from './components/Highlights';
import Header from './components/Header';
import Comments from './components/Comments';
import Questions from './components/Questions';
import Focus from './components/Focus';
import Actions from './components/Actions';
import Members from './components/Members';
import Scores from './components/Scores';
import { IReport } from './types/report';
import { dummyReport } from './data';

function App() {
	const [reports, setState] = useState<IReport[]>([]);
	let password = new URLSearchParams(window.location.search).get('key');

	useEffect(() => {
		if (password === 'dummy_report') {
			setState([dummyReport]);
		} else {
			fetch(
				'https://script.google.com/a/macros/circful.com/s/AKfycbwjSJGQssakabN8Bvh6riHSrKy490kvai9wsQocc6dPGo2E7hsC6oV5sP3kjQPzRAmd/exec?key=' +
					password
			)
				.then((res) => res.json())
				.then((json) => setState([json]));
		}
	}, [password]);

	if (reports.length === 0) {
		return (
			<>
				<span>Loading...</span>
			</>
		);
	}
	const report = reports[0];
	const isManagerReport = report.reportType === 'manager';
	document.title = isManagerReport ? report.i18n['report.manager.title'] : report.i18n['report.leadership.title'];
	return (
		<>
			<Header data={report.header} title={document.title} />
			<Summary data={report.summary} i18n={report.i18n} />
			<Engagement
				data={report.engagement}
				i18n={report.i18n}
				isManagerReport={isManagerReport}
				title={report.i18n['engagement.title']}
				weeksOfData={report.summary.weeksOfData}
			/>
			<Questions data={report.questionList} i18n={report.i18n} title={report.i18n['questions.title']} />
			{isManagerReport && (
				<Members
					data={report.teamMemberCards}
					i18n={report.i18n}
					title={report.i18n['team.member-highlights.title']}
				/>
			)}
			{!isManagerReport && (
				<Highlights
					data={report.teamHighlights}
					i18n={report.i18n}
					title={report.i18n['team.highlights.title']}
				/>
			)}
			{!isManagerReport && (
				<Focus data={report.leadershipTip} i18n={report.i18n} title={report.i18n['focus.title']} />
			)}
			{!isManagerReport && (
				<Highlights
					data={report.teamMemberHighlights}
					i18n={report.i18n}
					title={report.i18n['team.member-highlights.title']}
				/>
			)}
			<Comments
				data={report.teamMemberComments}
				i18n={report.i18n}
				title={report.i18n['team.member-comments.title']}
			/>
			{isManagerReport && (
				<Focus data={report.leadershipTip} i18n={report.i18n} title={report.i18n['focus.title']} />
			)}
			{isManagerReport && report.actionSteps !== null && (
				<Actions data={report.actionSteps} i18n={report.i18n} title={report.i18n['action-points.title']} />
			)}
			<Scores data={report.howAreTheseScoresCalculated} title={report.i18n['scores-explanation.title']} />
		</>
	);
}

export default App;
