import React from 'react';
import { IReportTeamMemberComments } from 'types/report';

interface IProps {
	title: string;
	i18n: Record<string, string>;
	data: IReportTeamMemberComments;
}

const Comments = ({ title, i18n, data }: IProps) => {
	if (data.sections.length > 0) {
		return (
			<div className='section comments'>
				<div className='section-header'>
					<span className='title'>{title}</span>
				</div>
				<div>
					{data.sections.map((section) => {
						return (
							<div className='comment'>
								<div className='comment-header'>
									<span className='comment-question'>{i18n[section.question]}</span>
									<span className='comment-metric'>{i18n['metric.' + section.metric + '.name']}</span>
								</div>
								<div>
									{section.comments.map((comment) => {
										return (
											<div className='comment-content dont-break'>
												{comment.employee && (
													<div className='comment-employee'>
														<span className='comment-employee-name'>
															{comment.employee}{' '}
														</span>
														<span className='comment-employee-role'>{comment.role}</span>
													</div>
												)}
												<span>"{comment.content}"</span>
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	} else {
		return (
			<div className='section comments'>
				<div className='section-header'>
					<span className='title'>{title}</span>
				</div>
				<div className='comment-content dont-break'>
					<span className='comment-employee-name'>{i18n['comments.empty']}</span>
				</div>
			</div>
		);
	}
};

export default Comments;
