import '../css/circle.css';
import React from 'react';
import { VictoryChart, VictoryLine, VictoryAxis, VictoryScatter, VictoryLabel, VictoryArea } from 'victory';
import { IReportSummary } from 'types/report';

interface IProps {
	data: IReportSummary;
	i18n: Record<string, string>;
}

const Summary = ({ data, i18n }: IProps) => {
	const participation = data.participation;
	const score = data.score;

	let minScoreHistory = 10;
	let maxScoreHistory = 0;

	const scoreHistory = data.scoreHistory.map((score) => {
		minScoreHistory = Math.min(minScoreHistory, score.score.value);
		maxScoreHistory = Math.max(maxScoreHistory, score.score.value);
		return {
			x: score.period,
			y: score.score.value,
		};
	});

	const trailingScoreHistory = data.scoreHistory.map((score) => {
		minScoreHistory = Math.min(minScoreHistory, score.trailingScore.value);
		maxScoreHistory = Math.max(maxScoreHistory, score.trailingScore.value);

		return {
			x: score.period,
			y: score.trailingScore.value,
		};
	});

	const dataLabels = data.scoreHistory
		.map((score, index) => {
			const dy = -12;
			let hideTrailing = score.score.value === score.trailingScore.value;

			let scoreDy = dy;
			let trailingScoreDy = -dy;

			if (score.score.value < score.trailingScore.value) {
				scoreDy = -dy;
				trailingScoreDy = dy;
			}
			const scoreLabels = [
				<VictoryLabel
					datum={{ x: index + 1, y: score.score.value }}
					text={score.score.value ? score.score.value.toFixed(1) : ''}
					dy={scoreDy * 1.2}
					textAnchor='middle'
					style={{ fill: '#DB7600' }}
				/>,
			];

			if (!hideTrailing) {
				scoreLabels.push(
					<VictoryLabel
						datum={{ x: index + 1, y: score.trailingScore.value }}
						text={score.trailingScore.value.toFixed(1)}
						dy={trailingScoreDy}
						textAnchor='middle'
						style={{ fill: '#000000' }}
					/>
				);
			}

			return scoreLabels;
		})
		.flat();

	return (
		<div className='section summary'>
			<div className='summary-participation summary-box'>
				<span className='summary-header'>{i18n['summary.participation-rate']}</span>
				<div className='summary-content'>
					<div className={`c100 p${participation.percentage}`}>
						<span className='title'>{participation.percentage}%</span>
						<span className='subtitle'>
							{participation.count} {i18n['out-of']} {participation.total}
						</span>
						<span className='subtitle employee-label'>
							{participation.total <= 1 ? i18n['employee-label'] : i18n['employees-label']}
						</span>
						<div className='slice'>
							<div className='bar'></div>
							<div className='fill'></div>
						</div>
					</div>
				</div>
			</div>
			<div className='summary-score summary-box'>
				<span className='summary-header'>{i18n['summary.score']}</span>
				<div className='summary-content'>
					<div className='summary-score-value'>{score.value.toFixed(1)}</div>
					<div className='summary-score-change'>
						<span className={`score-change ${score.change.category}`}>{score.change.value.toFixed(1)}</span>
					</div>
				</div>
			</div>
			<div className='summary-history-chart summary-box'>
				<span className='summary-header'>{i18n['summary.history']}</span>
				<div className='summary-content'>
					{scoreHistory.length > 1 ? (
						<VictoryChart
							domain={{ y: [minScoreHistory, maxScoreHistory] }}
							domainPadding={{ x: 0, y: 30 }}
							padding={{ top: 30, bottom: 30, left: 40, right: 40 }}
							height={200}>
							<VictoryAxis
								style={{
									axis: { stroke: 'none' },
								}}
							/>

							<VictoryLabel
								text={i18n['summary.label.2-weeks-data']}
								x={30}
								y={5}
								style={{
									fontFamily: 'inherit',
									fill: '#DB7600',
									fontSize: '14px',
									fontWeight: 'bold',
								}}
							/>

							<VictoryLabel
								text={i18n['summary.label.10-weeks-trendline']}
								x={30}
								y={24}
								style={{
									fontFamily: 'inherit',
									fill: '#5A5A5A',
									opacity: '85%',
									fontSize: '14px',
									fontWeight: 'bold',
								}}
							/>

							<VictoryArea
								data={scoreHistory}
								style={{
									data: { fill: '#FFAA00', opacity: 0.2 },
								}}
								interpolation='monotoneX'
							/>

							<VictoryLine
								data={trailingScoreHistory}
								style={{
									data: { stroke: '#B5B5B5', strokeWidth: 4 },
								}}
								interpolation='monotoneX'
							/>

							<VictoryScatter data={scoreHistory} style={{ data: { fill: '#FFAA00' } }} size={5} />
							<VictoryLine
								data={scoreHistory}
								style={{
									data: { stroke: '#FFAA00', strokeWidth: 4 },
								}}
								interpolation='monotoneX'
							/>

							{dataLabels}
						</VictoryChart>
					) : (
						<span className='summary-disabled'>{i18n['summary.disabled']}</span>
					)}
				</div>
			</div>
		</div>
	);
};

export default Summary;
