import React from 'react';
import ReactMarkdown from 'react-markdown';
import { IReportLeaderShipTip } from 'types/report';

interface IProps {
	title: string;
	i18n: Record<string, string>;
	data: IReportLeaderShipTip;
}

const Focus = ({ title, i18n, data }: IProps) => {
	if (data.title !== '') {
		return (
			<div className='section'>
				<div className='section-header'>
					<span className='title'>{title}</span>
				</div>
				<div className='leadership-tip container'>
					<div className='leadership-tip content-container'>
						<img
							className='leadership-tip illustration'
							src={data.illustration}
							alt='leadership tip illustration'
						/>
						<div className='leadership-tip content'>
							<div className='leadership-tip metric'>
								<span>{i18n['metric.' + data.metric + '.name']}</span>
							</div>
							<div className='leadership-tip title'>
								<span>{data.title}</span>
							</div>
							<div className='leadership-tip text'>
								<span>{data.content}</span>
							</div>
							<div className='leadership-tip text'>
								<ReactMarkdown>{data.markdown}</ReactMarkdown>
							</div>
						</div>
					</div>
					{data.button.label ? (
						<a className='leadership-tip primary button' href={data.button.url}>
							{data.button.label}
						</a>
					) : null}
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default Focus;
