import {} from '@testing-library/react';
import { IReportHighlight, IReportMember } from 'types/report';

interface IProps {
	title: string;
	i18n: Record<string, string>;
	data: IReportHighlight;
}

const Highlights = ({ title, i18n, data }: IProps) => {
	let membersByTeam = new Map<string, Array<IReportMember>>();
	data.members.forEach((member) => {
		let teamName = member.team ?? '';
		let team = membersByTeam.get(teamName);
		if (!team) {
			team = new Array<IReportMember>();
			membersByTeam.set(teamName, team);
		}

		team.push(member);
	});

	let turnoverCounter = 0;

	return (
		<div className='section highlights'>
			<div className='section-header'>
				<span className='title'>{title}</span>
			</div>
			<div className='highlights-table-header'>
				<div className='highlights-member' />
				{data.metrics.map((metric) => {
					return (
						<div className='highlights-heading-container'>
							<div className={`highlights-heading ${metric.hasChange ? 'score-with-change-header' : ''}`}>
								{i18n['highlights.' + metric.name + '.name']}
							</div>
						</div>
					);
				})}
			</div>
			{Array.from(membersByTeam.entries()).map((entry) => {
				return (
					<div className='highlights-table-body dont-break'>
						{entry[0] ? <div className='highlights-team-header'>{entry[0]}</div> : null}
						{entry[1].map((member) => {
							let scoreCounter: number = 0;
							return (
								<div className='highlights-table-row'>
									<div
										className={`highlights-member ${
											member.turnoverRisk !== undefined &&
											member.turnoverRisk !== 'low' &&
											`turnover ${member.turnoverRisk}`
										}`}>
										<span>{member.name === 'Company' ? i18n['company'] : member.name}</span>
										<span className='highlights-member turnover-risk'>
											{member.turnoverRisk !== undefined &&
												member.turnoverRisk !== 'low' &&
												turnoverCounter++ + 1 &&
												i18n['turnover-risk.' + member.turnoverRisk?.toLowerCase()]}
											{/* {if (member.turnoverRisk !== undefined && member.turnoverRisk !== 'low')
												{
													turnoverCounter++;
													return null;
												}
											} */}
										</span>
									</div>
									{member.scores.map((score) => {
										let isParticipationRate: boolean = scoreCounter++ === 0;
										return (
											<div className={`score ${score.change ? 'score-with-change' : ''}`}>
												<span className={`score-value ${score.category}`}>
													{formatScore(
														score.value === undefined ? null : score.value,
														isParticipationRate
													)}
												</span>
												{score.change && (
													<span className={`score-change ${score.change.category}`}>
														{score.change.value ? score.change.value.toFixed(1) : '0.0'}
													</span>
												)}
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
				);
			})}
			{turnoverCounter ? (
				<div className='turnover-disclaimer container'>
					<p className='turnover-disclaimer title'>{i18n['turnover-risk.disclaimer.title']}</p>
					<p className='turnover-disclaimer description'>{i18n['turnover-ristk.disclaimer.description-1']}</p>
					<p>{i18n['turnover-ristk.disclaimer.description-2']} </p>
				</div>
			) : null}
		</div>
	);
};

function formatScore(score: number | null, isParticipationRate: boolean): string {
	if (score === null) {
		return '-';
	}
	if (score < 10 && !isParticipationRate) {
		return score.toFixed(1).toString();
	}
	return score.toString();
}

export default Highlights;
