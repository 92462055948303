import React from 'react';
import { IReportEngagementItem } from '../types/report';

interface IProps {
	title: string;
	i18n: Record<string, string>;
	isManagerReport: boolean;
	data: IReportEngagementItem[];
	weeksOfData: number;
}

const Engagement = ({ title, i18n, data, isManagerReport, weeksOfData }: IProps) => {
	return (
		<div className='section engagement'>
			<div className='section-header'>
				<span className='title'>{title}</span>
				<span className='badge favorable'>{i18n['engagement.label.favorable']}</span>
				<span className='badge neutral'>{i18n['engagement.label.neutral']}</span>
				<span className='badge unfavorable'>{i18n['engagement.label.unfavorable']}</span>
			</div>
			<div className='data'>
				<div>
					<div className='engagement-metric engagement-header'>{i18n['engagement.label.metric']}</div>
					<div className='engagement-column'>
						{data.map((data) => {
							return (
								<div>
									<div className='engagement-metric'>
										<span className='engagement-metric-title'>
											{i18n['metric.' + data.metric + '.name']}
										</span>
										<span className='engagement-metric-subtitle'>
											{i18n['metric.' + data.metric + '.short-desc']}
										</span>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div>
					<div className='engagement-score engagement-header'>{i18n['engagement.label.score-header']}</div>
					<div className='engagement-column'>
						{data.map((data) => {
							return (
								<div>
									<div className='engagement-score'>
										<div className='score'>
											<span className={`score-value ${data.score.category}`}>
												{data.score.value > 0 ? data.score.value.toFixed(1) : '-'}
											</span>
											{data.score.change && (
												<span
													className={`score-change ${
														data.score.value > 0 ? data.score.change.category : 'neutral'
													}`}>
													{data.score.value > 0 ? data.score.change.value.toFixed(1) : '-'}
												</span>
											)}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div>
					<div className='engagement-distribution engagement-header'>
						{i18n['engagement.label.distribution-header']}
					</div>
					<div className='engagement-column'>
						{data.map((data) => {
							return (
								<div className='engagement-distribution'>
									<span className='engagement-distribution-no-value'>
										{data.distribution.filter((data) => data.value > 0).length === 0 &&
											i18n['engagement.label.distribution-no-value']}
									</span>
									{data.distribution
										.filter((data) => data.value > 0)
										.map((bucket) => {
											return (
												<span
													className={`engagement-distribution-value ${bucket.category}`}
													style={{
														width: bucket.value + '%',
														height: '2em',
													}}>
													{bucket.value > 8 ? `${bucket.value}%` : null}
												</span>
											);
										})}
								</div>
							);
						})}
					</div>
				</div>
				<div className='engagement-trailing-score-column'>
					<div className='engagement-trailing-score engagement-header'>
						{i18n['engagement.label.trailing-header']}
						{weeksOfData < 10 ? '*' : null}
					</div>
					<div className={`engagement-column${isManagerReport ? '-double' : ''}`}>
						{!isManagerReport ? (
							<div className='trailingScoreColumn'>
								{data.map((data) => {
									return (
										<div className='engagement-trailing-score'>
											<div className='score'>
												<span className={`score-value ${data.trailingScore.category}`}>
													{data.trailingScore.value.toFixed(1)}
												</span>
											</div>
										</div>
									);
								})}
							</div>
						) : (
							<div className='trailingScoreColumn'>
								<span className='trailingScoreSubTitle'>{i18n['team']}</span>
								{data.map((data) => {
									return (
										<div className='engagement-trailing-score'>
											<div className='score'>
												<span className={`score-value ${data.trailingScore.category}`}>
													{data.trailingScore.value.toFixed(1)}
												</span>
											</div>
										</div>
									);
								})}
								<span className='trailingScoreSubTitle'>{i18n['company']}</span>
								{data.map((data) => {
									return (
										<div className='engagement-trailing-score'>
											<div className='score'>
												<span
													className={`trailing-score score-value ${data.companyScore.category}`}>
													{data.companyScore.value.toFixed(1)}
												</span>
											</div>
										</div>
									);
								})}
							</div>
						)}
					</div>
				</div>
			</div>
			{weeksOfData < 10 ? (
				<div className='disclaimer-weeks-container'>
					<span className='disclaimer-weeks'>
						{i18n['engagement.disclaimer'].replace('{weeksOfData}', weeksOfData.toString())}
					</span>
				</div>
			) : null}
		</div>
	);
};

export default Engagement;
