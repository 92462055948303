import React from "react";
import ReactMarkdown from "react-markdown";

interface IProps {
    title: string;
    data: string[];
}

const Scores = ({ title, data }: IProps) => {
    return (
        <div className="section">
            <div className="section-header">
                <span className="title">{title}</span>
            </div>
            <div className="scores-faq container">
                {data.map((data) => {
                    return <ReactMarkdown>{data}</ReactMarkdown>;
                })}
            </div>
        </div>
    );
};

export default Scores;
